import { useCallback } from 'react';

import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { ListLayoutWithColorBox } from '@/components/genreHome/common/ListLayoutWithColorBox';
import { SectionByLayout, SelectionCarouselViewItem } from '@/features/genreHome/views/utils/viewDataUtils';
import type { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { SectionHeadContent } from '../../common/SectionLayout';
import { useSectionTrackingDataContext } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './SelectionCarousel.styles';
import { SelectionCarouselItem } from './SelectionCarouselItem';

export interface SelectionCarouselProps {
  section: SectionByLayout<SectionLayoutType.SelectionCarousel>;
}

export const SelectionCarousel = ({ section }: SelectionCarouselProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const render = useCallback(
    ({ item, index }: { item: SelectionCarouselViewItem; index: number }) => (
      <SelectionCarouselItem item={item} index={index} />
    ),
    [],
  );

  return (
    <TrackViewEvent screenName={sectionTrackingData.screenName} target="section" params={sectionTrackingData.params}>
      <section css={styles.sectionContainerStyle}>
        <header css={styles.selectionCarouselSectionHeaderStyle}>
          <SectionHeadContent title={section.title} link={section.more_details?.path} />
        </header>
        <div css={styles.selectionCarouselContentContainerStyle}>
          <ListLayoutWithColorBox
            arrowContainerCss={styles.arrowContainerStyle}
            items={section.items}
            renderItem={render}
          />
        </div>
      </section>
    </TrackViewEvent>
  );
};
