import { css } from '@emotion/react';

import { sectionHeaderStyle } from '@/components/genreHome/common/SectionLayout/SectionLayout.styles';
import { sectionMarginStyle } from '@/components/genreHome/sections/index.styles';
import { pageContainerStyle } from '@/components/styles';

export const sectionContainerStyle = sectionMarginStyle;

export const selectionCarouselSectionHeaderStyle = css`
  ${sectionHeaderStyle};
  ${pageContainerStyle};
`;

export const selectionCarouselContentContainerStyle = css`
  ${pageContainerStyle};
`;

export const arrowContainerStyle = css`
  align-items: flex-start;
  & > button {
    margin-top: 71px;
  }
`;
