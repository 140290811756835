import { useMemo, useState } from 'react';

import { IconBadgeAdult } from '@/assets/svgs/system';
import {
  Book,
  BookDefinition,
  BookPreset,
  RenewalBookPreset,
  RenewalBookPresetComponents,
  RenewalBookSizePreset,
} from '@/components/common/Book';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { ImageWithHandler } from '@/components/common/ImageWithHandler';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { a11y } from '@/components/styles/reset';
import { SelectionCarouselViewItem } from '@/features/genreHome/views/utils/viewDataUtils';
import { SectionItemLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';
import { addRDTTrackingURIQuery, getRDTGenrePrefix } from '@/utils/query';

import * as styles from './SelectionCarouselItem.styles';

const SelectionCarouselBookPreset: BookPreset[] = [
  RenewalBookPreset,
  RenewalBookSizePreset([{ value: { width: 150, height: 150, type: 400 } }]),
  components => {
    const presetComponents = { ...components } as RenewalBookPresetComponents;
    presetComponents.BookThumbnail = presetComponents.BookThumbnail.withOptions({ cropMode: 'none' });
    presetComponents.BookAdultBadge = presetComponents.BookNothing;

    return presetComponents;
  },
];

interface SelectionCarouselItemProps {
  item: SelectionCarouselViewItem;
  index: number;
}

export const SelectionCarouselItem = ({ item, index }: SelectionCarouselItemProps): ReactJSX.Element => {
  const [characterImageLoaded, setCharacterLoaded] = useState(false);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  const resourceFullLoaded = useMemo(() => {
    let fullLoaded = true;
    fullLoaded = !!item.contents.bg_color;
    if (item.contents.bg_image_url) {
      fullLoaded = !!backgroundImageLoaded;
    }
    if (item.layout === SectionItemLayoutType.SelectionCarouselCharacter) {
      fullLoaded = !!characterImageLoaded;
    }
    return fullLoaded;
  }, [characterImageLoaded, backgroundImageLoaded, item.contents.bg_image_url, item.layout, item.contents.bg_color]);

  const genrePrefix = getRDTGenrePrefix();
  const href = useMemo(
    () =>
      addRDTTrackingURIQuery(item.contents.landing_url || `/books/${item.contents.book.bookId as string}`, {
        sectionId: `${genrePrefix}selection_carousel`,
      }),
    [item.contents.landing_url, item.contents.book.bookId, genrePrefix],
  );

  const sectionTrackingData = useSectionTrackingDataContext();
  const bookTrackingOptions = useMemo(() => ({ extraParams: { book_index: index } }), [index]);
  const bookTrackingParams = useMemo(
    () => ({ ...sectionTrackingData.params, book_id: item.contents.book.bookId, book_index: index }),
    [sectionTrackingData, index, item],
  );
  const characterTrackingParams = useMemo(
    () => ({
      ...sectionTrackingData.params,
      item_id: item.id,
      item_index: index,
      item_title: item.contents.title,
      item_landing_url: item.contents.landing_url,
    }),
    [sectionTrackingData.params, item.id, index, item.contents.landing_url, item.contents.title],
  );

  const ImageComponent = useMemo(() => {
    if (item.layout === SectionItemLayoutType.SelectionCarouselBookCover) {
      return (
        <div css={styles.sectionCarouselBookContainerStyle}>
          <Book data={item.contents.book} trackingOptions={bookTrackingOptions}>
            {({ BookThumbnail }) => <BookThumbnail css={styles.selectionCarouselBookThumbnailStyle} />}
          </Book>
          <div css={styles.sectionCarouselBookMaskingStyle} />
        </div>
      );
    }

    return (
      <TrackViewEvent
        screenName={sectionTrackingData.screenName}
        target="selection_carousel"
        params={characterTrackingParams}>
        <ImageWithHandler
          css={styles.imageStyle}
          alt={`${item.contents.title} 캐릭터 이미지`}
          src={item.contents.main_image_url}
          onLoad={() => setCharacterLoaded(true)}
        />
      </TrackViewEvent>
    );
  }, [
    item.layout,
    item.contents.book,
    item.contents.title,
    item.contents.main_image_url,
    sectionTrackingData.screenName,
    bookTrackingOptions,
    characterTrackingParams,
  ]);

  const { screenName } = sectionTrackingData;
  const target = item.layout === SectionItemLayoutType.SelectionCarouselBookCover ? 'book' : 'selection_carousel';
  const trackingParams =
    item.layout === SectionItemLayoutType.SelectionCarouselBookCover ? bookTrackingParams : characterTrackingParams;

  return (
    <BookDefinition presets={SelectionCarouselBookPreset} trackingData={sectionTrackingData.bookDefinition}>
      <TrackClickEvent screenName={screenName} target={target} params={trackingParams}>
        <a href={href}>
          <span css={a11y}>{item.contents.title}으로 이동</span>
          <div css={styles.itemContainerStyle}>
            <div
              css={[styles.imageContainerStyle, !resourceFullLoaded && styles.placeHolderStyle]}
              data-testid="ImageContainer">
              {ImageComponent}
              {item.contents.bg_image_url && (
                <ImageWithHandler
                  alt={`${item.contents.title} 배경 이미지`}
                  css={styles.backgroundImageStyle}
                  draggable={false}
                  src={item.contents.bg_image_url}
                  onLoad={() => {
                    setBackgroundImageLoaded(true);
                  }}
                />
              )}

              {item.contents.bg_color && <div css={styles.colorBoxStyle(item.contents.bg_color)} />}
              {item.contents.book.cover?.isAdultOnly && (
                <div css={styles.adultBdageWrapperStyle}>
                  <IconBadgeAdult css={styles.badgeStyle} aria-label="19금" />
                </div>
              )}
              {item.contents.copyrights.length > 0 && (
                <div css={styles.copyrightContainerStyle}>
                  {item.contents.copyrights
                    .map((copyright, copyrightIndex) => ({ copyright, key: copyrightIndex }))
                    .map(({ copyright, key }) => (
                      <p css={styles.copyRightStyle} key={key}>
                        {copyright}
                      </p>
                    ))}
                </div>
              )}
            </div>
            <div css={styles.metaDataContainer}>
              <p css={styles.hookingSentenceStyle}>{item.contents.hooking_sentence}</p>
              <p css={styles.titleStyle}>{item.contents.title}</p>
            </div>
          </div>
        </a>
      </TrackClickEvent>
    </BookDefinition>
  );
};
