import { css, SerializedStyles } from '@emotion/react';

import { lineClamp, ZIndexLayer } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

const COLORBOX_Z_INDEX = ZIndexLayer.LEVEL_BASE;
const BACKGROUND_IMAGE_Z_INDEX = COLORBOX_Z_INDEX + 1;
const IMAGE_Z_INDEX = COLORBOX_Z_INDEX + 2;
const COPYRIGHT_Z_INDEX = COLORBOX_Z_INDEX + 3;
export const PLACE_HOLDER_Z_INDEX = IMAGE_Z_INDEX + 4;

export const itemContainerStyle = css`
  width: 100%;
  height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 209px;
    `,
  )}
`;

export const sectionCarouselBookContainerStyle = css`
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: ${IMAGE_Z_INDEX};
`;

export const selectionCarouselBookThumbnailStyle = css`
  border-radius: 4px;
  overflow: hidden;

  z-index: ${IMAGE_Z_INDEX};
`;

export const sectionCarouselBookMaskingStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${theme.colors.white};
  z-index: ${IMAGE_Z_INDEX + 1};
`;

export const imageContainerStyle = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 140px;
  position: relative;

  margin-bottom: 20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-bottom: 16px;
    `,
  )}
`;

export const placeHolderStyle = (theme: RIDITheme): SerializedStyles => css`
  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: ${PLACE_HOLDER_Z_INDEX};
    background: ${theme.colors.grey100};
    border-radius: 4px;
  }
`;

export const imageStyle = css`
  width: 250px;
  height: 140px;
  border-radius: 4px;
  z-index: ${IMAGE_Z_INDEX};
`;

export const backgroundBaseStyle = css`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 4px;
  overflow: hidden;
  transform: translate(-50%, 0);
`;

export const backgroundImageStyle = css`
  ${backgroundBaseStyle}
  max-width: 366px;
  z-index: ${BACKGROUND_IMAGE_Z_INDEX};
`;

export const copyrightContainerStyle = css`
  width: 100%;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: ${COPYRIGHT_Z_INDEX};
  position: absolute;
  left: 0;
  bottom: 8px;

  opacity: 0.2;
`;

export const copyRightStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 8px;
  letter-spacing: -0.01em;

  color: ${theme.colors.white};
  text-shadow: 0 0 2px ${theme.colors.black};

  ${lineClamp(2, '10px')};
  word-break: break-all;

  &:not(:first-of-type) {
    margin-top: 2px;
  }
`;

export const colorBoxStyle = (backgroundColor: string): SerializedStyles => css`
  ${backgroundBaseStyle}
  background-color: ${backgroundColor};
  z-index: ${COLORBOX_Z_INDEX};
`;

export const adultBdageWrapperStyle = css`
  ${backgroundBaseStyle}
  z-index: ${COPYRIGHT_Z_INDEX};
`;

export const metaDataContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
`;

export const hookingSentenceStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 250px;
  text-align: center;
  margin-bottom: 6px;

  ${lineClamp(2, '1.5rem')}
  white-space: pre-line;

  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.fillPrimary};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 227px;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4px;
    `,
  )}
`;

export const badgeStyle = css`
  font-size: 20px;
  position: absolute;
  top: 6px;
  right: 6px;

  /** dark mode에서 색상 반전 이슈 처리 */
  color-scheme: only light;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 16px;
    `,
  )}
`;

export const titleStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 250px;
  display: flex;
  font-size: 14px;
  text-align: center;
  color: ${theme.colors.fillSecondary};

  ${lineClamp(1, '16px')};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 227px;
      font-size: 13px;
    `,
  )}
`;
